<template>
  <div>
    <div class="info">
      <top-bar :title="!submitData.id?'新增检查':edit?'修改检查':'查看检查'" :left="true"></top-bar>
      <!-- 检查地点 -->
      <van-popup v-model="addrShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.addrList" value-key="label"
                    @confirm="addrConfirm" @cancel="addrShow = false"/>
      </van-popup>
      <!-- 建筑类别 -->
      <van-popup v-model="buildingTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.buildingTypeList" value-key="label"
                    @confirm="buildingTypeConfirm" @cancel="buildingTypeShow = false"/>
      </van-popup>
      <!-- 选择房屋 -->
      <van-popup v-model="buildingShow" position="bottom">
        <van-cascader v-model="submitData.building" title="请选择" :options="selectData.buildingList"
                      @close="buildingShow = false" @finish="buildingFinish" :field-names="fieldNames"/>
      </van-popup>
      <!-- 公共楼幢、工业楼幢 -->
      <van-popup v-model="publicBuildingShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.publicBuildingList" value-key="name"
                    @confirm="publicBuildingConfirm" @cancel="publicBuildingShow = false"/>
      </van-popup>
      <!-- 是否整改 -->
      <van-popup v-model="rectifyTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.rectifyTypeList" value-key="label"
                    @confirm="rectifyConfirm" @cancel="rectifyTypeShow = false"/>
      </van-popup>
      <!-- 所在小区 -->
      <van-popup v-model="subareaShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name"
                    @confirm="subareaConfirm" @cancel="subareaShow = false"/>
      </van-popup>
      <!-- 检查类别 -->
      <van-popup v-model="inspectionTypeShow" position="bottom">
        <van-cascader v-model="submitData.inspectionType" title="请选择" :options="selectData.inspectionTypeList"
                      @close="inspectionTypeShow = false" @finish="inspectionTypeFinish" :field-names="fieldNames"/>
      </van-popup>
      <!-- 问题类型 -->
      <van-popup v-model="problemTypeShow" position="bottom">
        <van-cascader title="请选择" :options="selectData.problemList"
                      @close="problemTypeShow = false" @finish="problemTypeFinish" :field-names="fieldNames"/>
      </van-popup>

      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">检查信息</span>
          </template>
        </van-cell>
        <van-cell title="检查地点" :value="selectMsg.addrTypeStr" @click="!isTasks?addrShow = edit : ''" :clickable="edit"
                  :value-class="{'value-common':selectMsg.addrTypeStr=='请选择'}" :is-link="edit" />
        <van-cell title="建筑类别" :value="selectMsg.buildingTypeStr" @click="buildingTypeShow = edit" :clickable="edit"
                  v-if="submitData.addrType=='5'&&!isTasks" :value-class="{'value-common':selectMsg.buildingTypeStr=='请选择'}" :is-link="edit"/>
        <van-cell title="住宅房屋" :value="selectMsg.buildingStr" @click="buildingShow = edit" :clickable="edit"
                  v-if="submitData.addrType=='5' && submitData.buildingType=='1' && !isTasks"
                  :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="edit"/>
        <van-cell title="所在小区" :value="selectMsg.subareaStr" @click="subareaShow = edit" :clickable="edit"
                  v-if="(submitData.addrType=='4' || submitData.buildingType == '3' || submitData.buildingType == '2') && !isTasks"
                  :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="edit"/>
        <van-cell :title="submitData.buildingType=='2'?'公共楼幢':'工业楼幢'" :value="selectMsg.publicBuildingStr"
                  @click="publicBuildingShow = edit" v-if="submitData.buildingType=='2'||submitData.buildingType=='3'"
                  :clickable="edit" :value-class="{'value-common':selectMsg.publicBuildingStr=='请选择'}" :is-link="edit"/>
        <van-cell :title="edit?'选择场所':'场所'" :value="selectMsg.placeStr" @click="selectPlace" :clickable="edit"
                  v-if="submitData.addrType=='4'  && !isTasks" :value-class="{'value-common':selectMsg.placeStr=='请选择'}" :is-link="edit"/>
        <van-cell title="详细地址">
          <van-field type="textarea" rows="1" autosize clearable clear-trigger="always" v-model="submitData.address" placeholder="请输入" :readonly="!edit||isTasks"/>
        </van-cell>
        <van-cell v-if="submitData.building" title="查看户信息" value="查看" value-class="check">
          <van-button type="info" plain size="normal" @click="toHouseInfo">查看</van-button>
        </van-cell>
        <van-cell title="整改" :value="selectMsg.rectifyTypeStr" @click="rectifyTypeShow = edit"
                  :value-class="{'value-common':selectMsg.rectifyTypeStr=='请选择'}" :is-link="edit"></van-cell>
        <van-cell title="检查类别" :value="selectMsg.inspectionTypeStr" @click="inspectionTypeShow = edit"
                  :value-class="{'value-common':selectMsg.inspectionTypeStr=='请选择'}" :is-link="edit"/>
        <van-cell v-if="selectData.checkItemsEntities.length > 0">
          <van-checkbox-group v-model="selectData.checkTypeValue" :disabled="!edit" @change="checkHandle">
            <van-checkbox v-for="item in selectData.checkItemsEntities" :key="item.itemId" :name="item.itemId" shape="square">{{item.itemName}}</van-checkbox>
          </van-checkbox-group>
        </van-cell>
        <van-field clearable clear-trigger="always" v-model="submitData.description" :border="false" class="left" rows="3"
                   autosize type="textarea" placeholder="请输入检查描述" :readonly="!edit"/>
        <van-row v-if="edit||fileList.length!==0">
          <van-col :span="24">
            <van-uploader v-model="fileList"  @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                          :before-read="beforeUpload" :show-upload="edit"/>
          </van-col>
        </van-row>
        <van-cell title="是否需要上报问题" v-if="edit">
          <van-switch v-model="problemSubmit" size="24px" @change="problemSubmitShow"/>
        </van-cell>
        <template v-if="problemSubmit">
          <div v-for="(item, index) in submitData.problemList">
            <van-cell :title="'问题'+(index+1)">
              <van-image :src="require('@/assets/img/problem_add.png')" height="25" width="25"
                         style="margin-right: 15px" @click="addProblem"/>
              <van-image :src="require('@/assets/img/problem_delete.png')" height="25" width="25"
                         style="margin-right: 15px" @click="deleteProblem"/>
              <van-image :src="require(`@/assets/img/problem_${problemShow?'shrink':'open'}.png`)" height="25"
                         width="25" @click="problemShow=!problemShow"/>
            </van-cell>
            <div style="padding: 0 20px" v-show="problemShow">
              <van-cell title="问题类型" :value="item.typeTitle" @click="checkProblemType(index)"/>
              <van-cell title="问题描述">
                <van-field
                    v-model="item.des"
                    rows="4"
                    autosize
                    type="textarea"
                    placeholder="这是一条问题描述"
                    input-align="right"
                />
              </van-cell>
              <van-row class="uploadBox">
                <van-col :span="24">
                  <van-uploader v-model="item.newfiles" @delete="uploaddelProblem" :name="index"
                                :before-read="beforeUpload" :after-read="uploadReadProblem"/>
                </van-col>
              </van-row>
            </div>
          </div>
        </template>
      </van-cell-group>
      <van-row class="btns">
        <van-col :span="24" v-if="edit">
          <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
        </van-col>
        <van-col :span="24" v-if="!edit">
          <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
        </van-col>
<!--        <van-col :span="12" v-if="!edit">-->
<!--          <van-button type="danger" size="large" round @click="deleteHandle">删除</van-button>-->
<!--        </van-col>-->
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'

export default {
  name: 'censor-add',
  data() {
    return {
      i: '',
      edit: false,
      file: {},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      addrShow: false,
      buildingTypeShow: false,
      buildingShow: false,
      publicBuildingShow: false,
      subareaShow: false,
      inspectionTypeShow: false,
      inspectionTimeShow: false,
      problemShow: true,
      problemSubmit: false,
      problemTypeShow: false,
      rectifyTypeShow: false,
      isTasks: false,
      selectMsg: {
        addrTypeStr: '请选择',
        buildingTypeStr: '请选择',
        buildingStr: '请选择',
        publicBuildingStr: '请选择',
        placeStr: '请选择',
        subareaStr: '请选择',
        inspectionTypeStr: '请选择',
        inspectionTime: '请选择',
        rectifyTypeStr: '请选择'
      },
      submitData: {
        id: '',
        addrType: '',
        addrId: '',
        buildingType: '',
        rentHouseCount: 0,
        buildingId: '',
        subarea: '',
        address: '',
        detailedAddressValue: [],
        inspectionType: '',
        inspectionTime: '',
        description: '',
        building: '',
        problemList: [],
        newFileMessages: [],
        deleteFiles: [],
        checkItemsEntities: [],
        rectifyType: '',
        unitId: '',
        houseId: '',
      },
      selectData: {
        addrList: [{label: "楼幢", value: "5"}, {label: "场所", value: "4"}],
        buildingTypeList: [{label: "住宅楼幢", value: "1"}, {label: "公共楼幢", value: "2"}, {label: "工业楼幢", value: "3"}],
        buildingList: [],
        publicBuildingList: [],
        subareaList: [],
        inspectionTypeList: [],
        problemList: [],
        rectifyTypeList: [{ label: "未整改", value: "0"}, { label: "自行整改完成", value: "1"}, {label: "其他整改完成", value: "2" }],
        checkItemsEntities: [],
        checkTypeValue: []
      },
      fileList: [],
    }
  },
  components: {
    topBar
  },
  watch: {
    'selectData.buildingList': {
      handler(newarr, oldarr) {
        if (this.$route.query.ids) {
          if (newarr !== []) {
            let selectedOptions = []
            let ids = this.$route.query.ids.split('-')
            ids.map(item => {
              if (item) {
                return item
              }
            })
            this.getIdsList(ids, 0, this.selectData.buildingList, selectedOptions)
            let obj = {
              selectedOptions,
              value: selectedOptions[selectedOptions.length - 1].value
            }
            this.buildingFinish(obj)
          }
        }
      }
    }
  },
  methods: {
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    // 查看户信息
    toHouseInfo() {
      this.$store.commit('setHouseId', this.submitData.building)
      this.$router.push('./house-info')
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/checkPicture/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.submitData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 删除问题图片
    uploaddelProblem(file, detail) {
      let i = detail.index
      this.submitData.problemList[detail.name].files.splice(i, 1)
      this.submitData.problemList[detail.name].problemUrls = file.filter((n) => n.uid !== file.uid)
      this.submitData.problemList[detail.name].files = this.submitData.problemList[detail.name].files.filter((n) => n.newFileName !== file.fileMessage.newFileName)
    },
    // 上传问题图片
    uploadReadProblem(file, detail) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/problem/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.submitData.problemList[detail.name].files.length != 0 ? this.submitData.problemList[detail.name].files.length : 0
            this.submitData.problemList[detail.name].files.push(fileMessage)
            this.submitData.problemList[detail.name].problemUrls.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 初始化
    init() {
      this.getSubareaList()
      this.getDictTypeList()
      this.getProblemList()
      this.getBuildingList()
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(nowDate)
      this.submitData.inspectionTime = nowDate
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }
    },
    // 获取房屋列表
    getBuildingList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.buildingList = data.liveWhichTree
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取楼幢列表
    getPublicBuildingList(buildingType) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: this.$orgId,
          buildingType: buildingType
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.publicBuildingList = data.page.list
          this.selectData.publicBuildingList.map(item => item.name = item.name + '幢')
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取字典
    getDictTypeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/checkTemplate/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          is_add: 1
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            let list = []
            data.page.list.map(item => {
              list.push({value: item.id, label: item.name})
            })
            this.selectData.inspectionTypeList = list
            if (!this.submitData.id && !this.isTasks) {
              this.selectMsg.inspectionTypeStr = list && list.length > 0 ? list[0].label : ''
              this.submitData.inspectionType = list && list.length > 0 ? list[0].value : ''
              let valueId = this.submitData.inspectionType
              this.getCheckList(valueId)
            }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取复选框
    getCheckList(typeId) {
      this.selectData.checkItemsEntities = []
      this.$http({
        url: this.$http.adornUrl('/wxapp/checkTemplate/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: typeId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.checkTemplateEntity.itemEntities !== []) {
            let list = []
            data.checkTemplateEntity.itemEntities.map(item => {
              list.push({itemId: item.id, itemName: item.itemContent, existsProblem: 0})
            })
            this.selectData.checkItemsEntities = list
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取字典
    getProblemList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'problemType',
          orgId: this.submitData.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.problemList = data.dicts
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/check/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.info.placeType !== 4) {
            this.selectMsg.addrTypeStr = '楼幢'
            this.submitData.addrType = '5'
            this.selectMsg.buildingTypeStr = data.info.placeTypeName
            this.submitData.buildingType = data.info.placeType.toString()
          } else {
            this.selectMsg.addrTypeStr = '场所'
            this.submitData.addrType = '4'
          }
          //获取检查类别多选框显示
          console.log(this.selectData.inspectionTypeList)
          let templist = data.info.checkItemsEntities
          for (let i = 0; i < templist.length; i++) {
            if (templist[i].existsProblem === 1) {
              this.selectData.checkTypeValue.push(templist[i].itemId)
            }
          }
          this.selectData.checkItemsEntities = templist
          this.selectMsg.buildingStr = data.info.buildingName
          this.submitData.address = data.info.buildingName
          this.selectMsg.placeStr = data.info.buildingName
          this.submitData.buildingId = data.info.buildingId
          this.submitData.addrId = data.info.buildingId
          this.submitData.inspectionType = data.info.checkType
          this.selectMsg.inspectionTypeStr = data.info.checkTypeName
          this.submitData.description = data.info.checkDes
          this.selectMsg.publicBuildingStr = data.info.buildingName
          this.submitData.subarea = data.info.subarea
          this.selectMsg.subareaStr = data.info.subareaName
          this.submitData.unitId = data.info.unitId
          this.submitData.houseId = data.info.houseId
          this.submitData.rectifyType = data.info.solvedStatus
          this.selectMsg.rectifyTypeStr = data.info.solvedStatusName
          let files = data.info.filesPath
          let urls = []
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].filePath)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: file.type,
              url: u,
              realUrl: file.filePath.replace(/\\/g, "/")
            }
            urls.push(url);
          }
          this.fileList = urls
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      if (this.submitData.addrType === '') {
        return this.$toast.fail('失败:请选择检查地点');
      }
      if (this.submitData.address === '') {
        return this.$toast.fail('失败:详细地址不能为空');
      }
      if (this.submitData.inspectionType === '') {
        return this.$toast.fail('失败:检查类别不能为空');
      }
      if (this.submitData.description === '') {
        return this.$toast.fail('失败:检查描述不能为空');
      }
      if (this.selectMsg.description === '') {
        return this.$toast.fail('失败:请先输入问题描述');
      }
      // 问题校验
      if (this.submitData.problemList.length != 0 && this.problemSubmit) {
        for (var i = 0; i < this.submitData.problemList.length; i++) {
          if (!this.submitData.problemList[i].type) {
            return this.$toast.fail('失败:请选择问题类型')
          }
          if (!this.submitData.problemList[i].des) {
            return this.$toast.fail('失败:请输入问题描述')
          }
        }
      }
      this.submitData.checkItemsEntities = this.selectData.checkItemsEntities
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/check/saveOrUpdate`),
        method: 'post',
        data: {
          id: this.submitData.id,
          //检查地点id
          addrId: this.submitData.addrId,
          //楼幢id
          buildingId: this.submitData.addrType == '5' ? this.submitData.buildingId : this.submitData.addrId,
          //单元id
          unitId: this.submitData.unitId,
          //房屋id
          houseId: this.submitData.houseId,
          //详细地址
          address: this.submitData.address,
          detailedAddress: this.submitData.detailedAddressValue,
          //检查地点类型
          placeType: this.submitData.addrType == '5' ? this.submitData.buildingType : this.submitData.addrType,
          //检查类型
          checkType: this.submitData.inspectionType,
          //检查描述
          checkDes: this.submitData.description,
          //检查图片
          fileMessages: this.submitData.newFileMessages,
          //修改图片组
          newFileMessages: this.submitData.newFileMessages,
          //复选框,检查子项
          checkItemsEntities: this.submitData.checkItemsEntities,
          //整改
          solvedStatus: this.submitData.rectifyType,
          //问题集合
          problemList: this.submitData.problemList,
          //小区
          subarea: this.submitData.subarea,
          //删除图片的id
          deleteFiles: this.submitData.deleteFiles
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                localStorage.setItem('isRefresh',1)
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 检查地点选择
    addrConfirm(value) {
      if (value) {
        this.selectMsg.addrTypeStr = value.label
        this.submitData.addrType = value.value
      }
      this.addrShow = false
    },
    // 建筑类别选择
    buildingTypeConfirm(value) {
      if (value) {
        this.selectMsg.buildingTypeStr = value.label
        this.submitData.buildingType = value.value
        if (value.value == 1) {
          this.getBuildingList()
        } else {
          this.getPublicBuildingList(value.value)
        }
      }
      this.buildingTypeShow = false
    },
    // 房屋选择
    buildingFinish(value) {
      if (value) {
        let options = value.selectedOptions
        this.submitData.addrId = options[options.length - 1].value
        this.submitData.buildingId = options[1].value
        this.submitData.subarea = options[0].value
        if (options[2]) {
          this.submitData.unitId = options[2].value
        } else {
          this.submitData.unitId = 0
        }
        if (options[3]) {
          this.submitData.houseId = options[3].value
        } else {
          // 不存在第四级，说明是没有单元的
          this.submitData.houseId = options[1].value
          this.submitData.unitId = 0
        }
        this.submitData.detailedAddressValue = options.map(item => {
          return item.value
        })
        this.selectMsg.buildingStr = ''
        this.submitData.building = value.value
        value.selectedOptions.map(item => {
          this.selectMsg.buildingStr += item.label
        })
        this.submitData.address = this.selectMsg.buildingStr
      }
      this.buildingShow = false
    },
    // 公共楼幢选择
    publicBuildingConfirm(value) {
      if (value) {
        this.selectMsg.publicBuildingStr = value.name
        this.submitData.addrId = value.id
        this.submitData.buildingId = value.id
        this.submitData.detailedAddressValue = [value.id]
        this.submitData.address = value.name
        this.submitData.unitId = 0
        this.submitData.houseId = 0
      }
      this.publicBuildingShow = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    // 检查类型选择
    inspectionTypeFinish(value) {
      if (value) {
        this.selectMsg.inspectionTypeStr = value.selectedOptions[0].label
        this.submitData.inspectionType = value.value
        this.getCheckList(value.value)
      }
      this.inspectionTypeShow = false
    },
    // 是否整改
    rectifyConfirm(value) {
      if (value) {
        this.selectMsg.rectifyTypeStr = value.label
        this.submitData.rectifyType = value.value
      }
      this.rectifyTypeShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(value)
        this.submitData.inspectionTime = formatterDateTimeWithOutSec(value)
      }
      this.inspectionTimeShow = false
    },
    // 选择问题类型
    checkProblemType(index) {
      this.problemTypeShow = true
      this.i = index
    },
    // 是否上报问题
    problemSubmitShow() {
      if (this.problemSubmit && this.submitData.problemList.length === 0) {
        this.addProblem()
      }
    },
    // 问题类型选择
    problemTypeFinish(value) {
      if (value) {
        this.submitData.problemList[this.i].typeTitle = value.selectedOptions[value.selectedOptions.length - 1].label
        this.submitData.problemList[this.i].type = value.value
      }
      this.problemTypeShow = false
    },
    // 新增问题
    addProblem() {
      var problem = {
        type: 0,
        typeTitle: '请选择',
        des: '',
        problemUrls: [],
        files: [],
        opened: true
      }
      this.submitData.problemList.push(problem)
    },
    // 删除问题
    deleteProblem(i) {
      this.submitData.problemList.splice(i, 1)
      if (this.submitData.problemList.length === 0) {
        this.problemSubmit = false
      }
    },
    // 选择场所
    selectPlace() {
      this.$router.push('./place-list')
    },
    // 选择完场所
    checkPlace(msg) {

    },
    checkHandle (value) {
      for (let j = 0; j < this.selectData.checkItemsEntities.length; j++) {
        this.selectData.checkItemsEntities[j].existsProblem = 0
      }

      for (let i = 0; i < value.length; i++) {
        for (let j = 0; j < this.selectData.checkItemsEntities.length; j++) {
          if (value[i] == this.selectData.checkItemsEntities[j].itemId) {
            this.selectData.checkItemsEntities[j].existsProblem = 1
          }
        }
      }
    },
    getIdsList(ids, index, arr, list) {
      let i = arr.findIndex(item => ids[index] == item.value)
      if (i !== -1) {
        list.push(arr[i])
      }
      if (arr[i].children && ids[index++]) {
        this.getIdsList(ids, index++, arr[i].children, list)
      }
    },
    formTasks() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/dailytasks/inspection/executionTask'),
        method: 'post',
        data: this.$http.adornData({
          dttId: this.$route.query.dttId,
          dictId: this.$route.query.dictId,
          targetId: this.$route.query.targetId,
          address: this.$route.query.address,
          objectType: this.$route.query.objectType
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.entity.placeType !== 4) {
            this.selectMsg.addrTypeStr = '楼幢'
            this.submitData.addrType = '5'
            this.selectMsg.buildingTypeStr = data.entity.placeTypeName
            this.submitData.buildingType = data.entity.placeType.toString()
          } else {
            this.selectMsg.addrTypeStr = '场所'
            this.submitData.addrType = '4'
          }
          this.submitData.buildingId = data.entity.buildingId
          this.submitData.addrId = data.entity.buildingId
          this.selectMsg.buildingStr = data.entity.buildingName
          this.submitData.address = data.entity.address
          this.selectMsg.placeStr = data.entity.buildingName
          this.submitData.inspectionType = data.entity.checkType
          this.selectMsg.inspectionTypeStr = data.entity.checkTypeName
          this.selectMsg.publicBuildingStr = data.entity.buildingName
          this.submitData.subarea = data.entity.subarea
          this.submitData.unitId = data.entity.unitId
          this.submitData.houseId = data.entity.houseId
          let typeId = this.submitData.inspectionType
          this.getCheckList(typeId)
          this.isTasks = true
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
  },
  activated () {
    if (this.$store.state.placeInfo.addrId) {
      this.submitData.addrId = this.$store.state.placeInfo.addrId
      this.submitData.buildingId = 0
      this.submitData.address = this.$store.state.placeInfo.address
      this.selectMsg.placeStr = this.$store.state.placeInfo.placeTitle
      this.submitData.unitId = 0
      this.submitData.houseId = 0
    }
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    if (this.$route.query.tasks) {
      this.formTasks()
    } else {
      this.selectMsg.addrTypeStr = '楼幢'
      this.submitData.addrType = '5'
      this.selectMsg.buildingTypeStr = '住宅楼幢'
      this.submitData.buildingType = '1'
      this.selectMsg.rectifyTypeStr = '自行整改完成'
      this.submitData.rectifyType = '1'
    }
    if (this.$route.query.ids) {
      this.addrConfirm({label: "楼幢", value: "1"})
      this.buildingTypeConfirm({label: "住宅楼幢", value: "1"})
    }
    this.init()
  },
  beforeDestroy() {
    this.$store.state.placeInfo = {}
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>

<style lang="scss" scoped>
.info {
  height: 1640px;
  overflow-y: scroll;
  margin-bottom: 150px;
}
.check {
  color: #3F8BFC !important;

  .van-button {
    width: 92px;
    height: 52px;
    font-size: 24px;
    border-color: #4E7CF5;
    border-radius: 12px;
  }

  ::v-deep .van-button--normal {
    padding: 0;
  }
}

::v-deep .van-checkbox {
  margin-right: 20px;
  margin-bottom: 30px;
}
</style>
